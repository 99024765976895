// スマホだと2回タップしないとリンク先に飛ばない問題の対策
window.ontouchstart = function() {};
window.addEventListener('touchstart', function() {}, true);
window.addEventListener('touchstart', function() {}, false);

// 年数（コピーライト）
$(function(){
  var year = new Date().getFullYear();
  $("#year").html(year);
});

// menu
// $(function() {
//   $('.header-menu-btn').on('click', function() {
//     $('.header-menu-btn-inner').toggleClass('active');
//     $('.header-nav').toggleClass('active');
//     $('body').toggleClass('is-fixed');
//   });
// });

//Menu
var state = false;
var pos;
$(".header-menu-btn").on("click",function(){
  if (state == false) {
    pos = $(window).scrollTop();
    $('body').addClass('is-fixed').css({'top': -pos});
    state = true;
  } else {
    $('body').removeClass('is-fixed').css({'top': 0});
    window.scrollTo(0, pos);
    state = false;
  }

  $('.header-menu-btn-inner').toggleClass('active');
  $('.header-nav').toggleClass('active');
});

// slick
$(function () {
  $("#kvSlider")
    .on("init", function () {
      $('#kvSlider .slick-slide[data-slick-index="0"]').addClass("add-animation");
    })
    .slick({
      autoplay: true,
      fade: true,
      speed: 2000,
      arrows: false,
      autoplaySpeed: 4000,
      pauseOnFocus: false,
      pauseOnHover: false, 
    })
    .on({
      beforeChange: function (event, slick, currentSlide, nextSlide) {
        $(".slick-slide", this).eq(nextSlide).addClass("add-animation");
        $(".slick-slide", this).eq(currentSlide).addClass("remove-animation");
      },
      afterChange: function () {
        $(".remove-animation", this).removeClass(
          "remove-animation add-animation"
        );
      },
    });
});


// GSAP（オープニングアニメーション）
const jsKvCopyItem = "#jsKvCopy > span";
const jsKvImage = "#jsKvImage";
const jsKvLayer = "#jsKvLayer";
const jsKvPickup = "#jsKvPickup";
const jsKvLayer02 = "#jsKvLayer02";

gsap.set(jsKvCopyItem, {
  opacity: 0,
  y: 50,
  ease: "power4.out",
});
gsap.set(jsKvImage, {
  opacity: 0,
  y: 50,
  ease: "power4.out",
});
gsap.set(jsKvLayer, {
  opacity: 0,
  y: 50,
  ease: "power4.out",
});
gsap.set(jsKvPickup, {
  opacity: 0,
  x: -100,
  ease: "power4.out",
});
gsap.set(jsKvLayer02, {
  opacity: 0,
  x: -100,
  ease: "power4.out",
});

const tl = gsap.timeline();

tl.to(
  jsKvCopyItem, 
  {
    opacity: 1,
    y: 0,
    duration: 1,
    delay: 0.8,
    stagger: {
      amount: 0.5,
      from: "start",
      ease: "power4.out",
    },
  }
)
.to(
  jsKvLayer, 
  {
    opacity: 1,
    y: 0,
    duration: 0.6,
  },
  "+=0.1"
)
.to(
  jsKvImage, 
  {
    opacity: 1,
    y: 0,
    duration: 0.6,
  },
  "-=0.15"
)
.to(
  jsKvPickup, 
  {
    opacity: 1,
    duration: 0.6,
    x: 0,
  },
  "-=0.25"
)
.to(
  jsKvLayer02, 
  {
    opacity: 1,
    duration: 0.6,
    x: 0,
  },
  "-=0.6"
);

// スクロールアニメーション
let fadeupEffect = gsap.utils.toArray(".fadeInUp");
fadeupEffect.forEach((target) => {
  gsap.fromTo(  // fromTo アニメーション開始時と終了時の状態を指定
    target, 
    { // アニメーション開始時の状態
      y: 50, 
      autoAlpha: 0,
    },
    { // アニメーション終了時の状態
      y: 0,
      autoAlpha: 1,
      duration: 1,
      ease: "power4.out",
      scrollTrigger: {
        trigger: target,
        start: "top 70%",
        end: "bottom 10%",
      },
    }
  );
});

// ディールエージェントの強み（時間差アニメーション）
let stepEffect = document.querySelectorAll('.stepInWrap');
stepEffect.forEach((target)=>{
  let targetChild = target.querySelectorAll('.stepIn');
  gsap.fromTo( // fromTo アニメーション開始時と終了時の状態を指定
    targetChild,
    { // アニメーション開始時の状態
      y: 50,
      autoAlpha: 0,
    },
    { // アニメーション終了時の状態
      y: 0,
      duration: 1.5,
      delay: 0.5,
      autoAlpha: 1,
      ease: "power4.out",
      stagger:{
        each: 0.2
      },
      scrollTrigger: {
        trigger: target,
        start: "top 70%",
        end: "bottom 10%",
      }    
    })
});
